import React from "react";
import * as constants from "../constants";
import classNames from "classnames";

const Content = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <React.Fragment>
      <div
        style={constants.mainViewportHeightStyle}
        className={classNames("z-0", className)}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export { Content };
