import { Dispatch, SetStateAction } from "react";
import { CircleButton, Modal } from ".";

import { ReactComponent as FbLogo } from "../assets/icons/button_facebook.svg";
import { ReactComponent as PinterestLogo } from "../assets/icons/button_pinterest.svg";
import { ReactComponent as TwitterLogo } from "../assets/icons/button_x_logo_white.svg";
import { IButtonProps } from "./Button";

//"Explore Kelly Gang history and discover how the trials of the Kelly family link to the Beechworth Historic Precinct.";
const baseUrl = "https://courthouse.explorebeechworth.com.au/";
const FB_URL = `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}`;
const PINTEREST_URL = `https://pinterest.com/pin/create/button/?url=${baseUrl}&media=&description=Explore%20Kelly%20Gang%20history%20and%20discover%20how%20the%20trials%20of%20the%20Kelly%20family%20link%20to%20the%20Beechworth%20Historic%20Precinct.`;
const TWITTER_URL = `https://twitter.com/intent/tweet?url=${baseUrl}&text=Explore%20Kelly%20Gang%20history%20and%20discover%20how%20the%20trials%20of%20the%20Kelly%20family%20link%20to%20the%20Beechworth%20Historic%20Precinct.`;

interface IShareModalProps {
  showModal: boolean;
  setShowExternal: Dispatch<SetStateAction<boolean>>;
}

const SMButton: React.FC<IButtonProps & { to: string }> = ({
  children,
  to,
}) => {
  return (
    <a href={to} target="_blank">
      <CircleButton className="h-14 w-14 transition-opacity hover:opacity-70">
        {children}
      </CircleButton>
    </a>
  );
};

const ShareModal: React.FC<IShareModalProps> = (props) => {
  const { showModal, setShowExternal } = props;
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display tailwindHeight="h-shareModal w-shareModal">
        <Modal.Body>
          <div className="p-16 text-center space-y-4">
            <div className="font-bold text-shareTitle">Share our story</div>
            <div className="text-xl">
              Enjoyed the experience? Tell your friends.
            </div>
            <div className="flex place-content-center p-5 space-x-4">
              <SMButton to={FB_URL}>
                <FbLogo fill="white" width="50px" height="50px" />
              </SMButton>
              <SMButton to={PINTEREST_URL}>
                <PinterestLogo fill="white" width="50px" height="50px" />
              </SMButton>
              <SMButton to={TWITTER_URL}>
                <TwitterLogo fill="white" width="50px" height="50px" />
              </SMButton>
            </div>
          </div>
        </Modal.Body>
      </Modal.Display>
    </Modal>
  );
};

export { ShareModal };
