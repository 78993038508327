import React, { useState, useEffect } from "react";
import axios from "axios";

import {
	Content,
	TimelineDisplay,
	Loader,
  prepDateForStories
} from "../components";
import { API, HEADERS } from "../constants";

const Courthouse:React.FC = () => {

	const [data, setData] = useState<any[]|null>(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.COMMUNITY, HEADERS);

			// const totalNodes = response.data.community_nodes.length;
			const modifiedData = await prepDateForStories(response.data.community_nodes);
			
      setData(modifiedData);
    } catch(error) {
      console.error(error);
    }
  }

	return <Content>
    <div className='w-full h-full bg-timeline'>
      <Loader isLoading={!data}>
        <TimelineDisplay
          data={data || []}
          useDatesToArrange={true}
          filterOptions={[]}
          title='Courthouse'
        />
      </Loader>
    </div>
	</Content>
}

export default Courthouse;