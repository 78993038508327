import React, { useState, useEffect } from "react";
import { isSmallAtom } from '../atoms';
import { useAtom } from "jotai";

import { ItemModal } from ".";
import { TimelineDateDisplay } from "./TimelineDateDisplay";
import { IFilterOption } from "./FilterMenu";
import { ManualFilterMenu } from "./FilterMenu";

const randomYPos = (index: number):number => {
  const vpH = window.innerHeight;
  switch(index%6) {
    case 0:
      return vpH*0.002;
    case 1:
      return -vpH*0.00175;
    case 2:
      return vpH*0.003;
    case 3:
      return vpH*0.001;
    case 4:
      return -vpH*0.0025;
    default:
      return 0;
  }
}

const TimelineThumb = ({ src, alt }:{ src: string, alt: string }) => {
  return (
    <img
      alt={alt}
      src={src}
      className='w-timelineMobile h-timelineMobile md:w-timelineNode md:h-timelineThumb object-cover pointer-events-none'
    />
  );
}

const TimelineItem = (
  data: any,
  index: number,
  openModal:any,
  setShownIndex: any,
  isSmall: boolean,
  filter: string | null,
) => {

  const thumbUrl = data.media_items[0]?.thumb_url;
  const shortDesc = data.short_description;
  const title = data.title;
  const date = data.date;
  const type = data.node_type;

  const style =  isSmall ? {} : {
    transform: `translate(0rem, ${randomYPos(index)}rem)`
  }

  const filtered = filter === null || type === filter;
  const className = `w-auto ${!filtered && 'pointer-events-none opacity-20 '}`

  return <div key={index}
    id={'item' + index.toString()}
    className={className}
    style={style}
  >
    <div className={'bg-black w-timelineMobile md:w-timelineNode h-auto animate-fadein'}>
      <div
        className='bg-bgSolid transition-opacity w-full h-auto hover:opacity-70 cursor-pointer'
        onMouseUp={() => openModal()}
        onMouseDown={() => setShownIndex()}
      >
        { thumbUrl && <TimelineThumb src={thumbUrl} alt={shortDesc}/> }
        <div className='w-full p-5 space-y-4'>
          { date && TimelineDateDisplay(date.display, type) }
          <div className='font-bold text-lg md:text-xl'>{title}</div>
          { !isSmall && <div className=''>{shortDesc}</div> }
        </div>
      </div>
    </div>
  </div>
}

interface ITimelineDisplayProps {
  data: any[];
  useDatesToArrange: boolean;
  filterOptions: IFilterOption[];
  title: string;
}

const TimelineDisplay:React.FC<ITimelineDisplayProps> = ({
  data,
  filterOptions,
  useDatesToArrange,
  title,
}) => {

  const totalNodes = data.length;

  const [isSmall] = useAtom(isSmallAtom);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [shownIndex, setShownIndex] = useState<number>(0);
  const [selectedNodeData, setSelectedNodeData] = useState<any|null>(null);
  const [filter, setFilter] = useState<string|null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [nodeShown, setNodeShown] = useState<number>(0);
  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!showModal) setSelectedNodeData(null);
  }, [showModal]);

  useEffect(() => {
    updateTimelineDisplay(0);
  }, []);

  const openModal = (data: any) => {
    setSelectedNodeData(data);
    setShowModal(true);
    setFilterMenuOpen(false);
  }

  const handleScrollJump = (index: number) => {
    const display = document.getElementById("display");
    const node = document.getElementById('item' + index.toString());
    if (display && node) {
      const newScrollPos = node.offsetLeft - window.innerWidth*0.5 + 140;
      display.scrollTo({
        left: newScrollPos,
        behavior: 'smooth',
      })
      updateTimelineDisplay(newScrollPos);
    }
    setShownIndex(index);
  }

  const handleNodeClicked = (node: any, index: number) => {
    openModal(node);
    handleScrollJump(index);
  }

  const onNodeDown = (index: number) => {
    setShownIndex(index);
  }

  const Line = () => {
    const getIndex = Math.min(Math.floor(nodeShown), data.length - 1);
    const xpos = data[getIndex].xpos;
    const white = { width: `${xpos}%`, height: '2px' }
    const gray = { width: `${100-xpos}%`, height: '2px' }
    return <div className='absolute w-full flex' style={{'paddingTop': '1px'}}>
      <div className='bg-white -my-0.5' style={white} />
      <div className='bg-timelineGray -my-0.5' style={gray} />
    </div>
  }

  const horizontalScroll = (event:any) => {
    const display = document.getElementById('display');
    const currentScrollPos = display?.scrollLeft;
    const newScrollPos = Math.max(0, currentScrollPos + event.deltaY);
    display?.scrollTo({
      top: 0,
      left: newScrollPos,
      behavior: 'smooth'
    })
    updateTimelineDisplay(newScrollPos);
  }

  const handleScroll = (event:any) => {
    const display = document.getElementById('display');
    const currentScrollPos = display?.scrollLeft;
    updateTimelineDisplay(currentScrollPos || 0);
  }

  const handleMouseMove = (event: any) => {
    if (isDragging) {
      const display = document.getElementById('display');
      if (display) {
        const currentScrollPos = display?.scrollLeft;
        const newScrollPos = Math.max(0, currentScrollPos - event.movementX);
        display?.scrollTo({
          left: newScrollPos,
        })
        updateTimelineDisplay(newScrollPos);
      }
    }
  }

  const updateTimelineDisplay = (curPos: number) => {
    const denominator = isSmall ? 256 : 296;
    const lastNode = (window.innerWidth + curPos - 32 - 140)/denominator;
    setNodeShown(lastNode);
    if (!showModal) {
      const lastShownIndex = Math.floor(lastNode);
      setShownIndex(lastShownIndex);
    }
  }

  const checkLabel = (index:number, date:string) => {
    const baseClass = 'text-white text-xs font-bold pointer-events-none pt-4 max-w-timelineDate';
    switch(index) {
      case(0):
        return <div className={`${baseClass} -ml-2`}>
          {date}
        </div>
      case(totalNodes-1):
        return <div className={`${baseClass} transform -translate-x-full`}>
          Now
        </div>
      case(shownIndex):
        return <div className={`${baseClass} text-center transform -translate-x-1/2 -translate-y-full -my-4`}>
          {date}
        </div>
      default: return null;
    }
  }

  return <div className='h-full relative'>
    {/* {
      !isSmall && 
      <div className="absolute top-0 ml-8 mt-8 text-white text-4xl font-bold">
        {title}
      </div>
    } */}
    <ItemModal
      data={selectedNodeData}
      showModal={showModal}
      setShowExternal={setShowModal}
    />
    <div
      className='h-full overflow-x-auto'
      id='display'
      onWheel={horizontalScroll}
      onScroll={handleScroll}
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
      onMouseMove={handleMouseMove}
    >
      <div id='content' className='h-5/6 w-max flex flex-wrap items-center space-x-4 px-8'>
        {
          data.map((d, index) => {
            return TimelineItem(
              d,
              index,
              () => handleNodeClicked(d, index),
              () => onNodeDown(index),
              isSmall,
              filter
            );
          })
        }
      </div>
    </div>
    <div className='absolute bottom-0 inset-x-0 w-full flex place-content-center items-center h-28 md:h-1/6 pointer-events-none'>
      <div className='relative w-5/6 pointer-events-auto animate-fadeinDelay' >
        {
          data.map((d, index) => {
            if (index !== 0 && index !== data.length - 1 && filter !== null && d.node_type !== filter) return null;
            const isShown = shownIndex === index;
            const color =  index <= nodeShown ? 'bg-white' : 'bg-timelineGray' // progress
            const size = isShown && showModal ? 200 : 100; // size of circle
            const style = { left: `${d.xpos}%` } // x position style
            return <div className='absolute' key={index} style={style}>
              <button
                key={index}
                className={`absolute transition-transform w-2 h-2 z-10 hover:scale-200 rounded-full ${color} transform scale-${size} -translate-y-1/2 -translate-x-1/2`}
                onClick={() => handleScrollJump(index)}
              />
              { d.date && checkLabel(index, d.date.display) }
            </div>
          })
        }
        <Line/>
      </div>
    </div>
    {
      filterOptions.length > 0 && <ManualFilterMenu
        options={filterOptions}
        setFilter={setFilter}
        setOpen={setFilterMenuOpen}
        menuOpen={filterMenuOpen}
      />
    }
  </div>
}

export default TimelineDisplay;