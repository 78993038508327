import { ReactComponent as CloseIcon } from '../assets/icons/button_close.svg'
import parse from 'html-react-parser';
import { Dispatch, SetStateAction } from 'react';

export const CaptionBox = ({
  caption,
  credit,
  toggleShowCaption,
}:{
  caption: string,
  credit: string,
  toggleShowCaption: Dispatch<SetStateAction<boolean>>
}) => {
  const parsedCaption = parse(caption);
  const parsedCredit = parse(credit);
  return <div className='bg-btnTextColor animate-fadein w-96 h-auto pt-1 pr-4 pl-8 pb-8 -mr-4'>
    <div className='flex justify-end'>
      <button onClick={() => toggleShowCaption(false)} className='pointer-events-auto'>
        <CloseIcon width='40px' height='40px' stroke='black' strokeWidth={1.5}/>
      </button>
    </div>
    <div className='text-left'>
      {parsedCaption} <span className='italic'> {parsedCredit}</span>
    </div>
  </div>
}
