// Style for (viewport height - navbar height)
// navbar height is determined in tailwind.config.js
export const mainViewportHeightStyle = {
  height: "calc(100% - 3.5rem)",
};

export const mobileWidth = 830;

export const TICKETS_URL =
  "https://ecom.roller.app/beechworthhistoricprecinct/beechworthcourthousekellytrials/en/home";

export const DOMAIN = "https://beechworth.herokuapp.com/";
//export const BASE_API = DOMAIN + 'api/v1/data/';
export const BASE_API =
  "https://lightwell-beechworth.s3.ap-southeast-2.amazonaws.com/endpoints/";

export const API = {
  COMMUNITY: BASE_API + "community_nodes.json",
  DISCOVER: BASE_API + "discover_nodes.json",
  RESOURCES: BASE_API + "resource_nodes.json",
  MAP: BASE_API + "map_nodes.json",
  STORIES: BASE_API + "timeline_nodes.json",
  ABOUT: BASE_API + "about.json",
  LANDING: BASE_API + "introduction.json",
};

export const HEADERS = { headers: { "Access-Control-Allow-Origin": DOMAIN } };
