import { useState } from "react";
import { ReactComponent as InactiveIcon } from '../assets/icons/button_filter_inactive.svg';
import { ReactComponent as ActiveIcon } from '../assets/icons/button_filter_active.svg';

export interface IFilterOption {
  label?: string;
  value: string | null;
  Icon?: any;
}

interface IMenuButtonProps {
  readonly name?:string;
  readonly Icon?:any;
  readonly className?: string;
  readonly selected?: boolean;
  readonly blackIcon?: boolean;
  onClick?: any;
}

const MenuButton:React.FC<IMenuButtonProps> = ({
  Icon,
  name,
  className,
  onClick,
  blackIcon
}) => {
  const base='w-screen md:w-56 h-12 flex flex-wrap justify-center place-content-center cursor-pointer'
  return (
    <div
      className={`${base} ${className}`}
      onClick={onClick}
    >
        {
          !Icon ? null :
          <div className='w-7 h-7 mr-1'>
            <Icon stroke={blackIcon ? 'black':'white'} fill='none'/>
          </div>
        }
        <div className='flex flex-wrap content-center'>
          {name}
        </div>
    </div>
  );
}

const MenuSelect:React.FC<IMenuButtonProps> = ({
  selected,
  ...restProps
}) => {
  const bgColor = selected ? 'transition-colors bg-btnTextActive' : 'transition-colors bg-black hover:bg-btnBgHover';
  return <MenuButton
    className={bgColor}
    {...restProps}
  />
}

const MenuToggle = ({
  onClick,
  Icon,
  displayString,
}:IMenuButtonProps & { displayString?: string | null }) => {
  const mainBtnClass = 'bg-white text-buttonSize text-black transition-colors hover:bg-gray-300'
  const name = displayString && displayString !== 'All' ? `Filter (${displayString})` : 'Filter';
  return <MenuButton
    name={name}
    Icon={Icon}
    onClick={onClick}
    className={mainBtnClass}
    blackIcon
  />
}

interface IFilterMenuProps {
  options: IFilterOption[];
  setFilter?: any; // setState function
}

export const ManualFilterMenu = ({
  options,
  setFilter,
  setOpen,
  menuOpen,
}:IFilterMenuProps & {
  setOpen: any; // manual setOpen function
  menuOpen: boolean;
}) => {

  const [selected, setSelected] = useState<string|null>(null);
  const [displayString, setDisplayString] = useState<string|null>(null);

  const toggleMenu = () => {
    setOpen(!menuOpen);
  }

  const selectOption = (option: any) => {
    setSelected(option.value);
    setDisplayString(option.label ? option.label : (option.value || ''))
    if (setFilter) setFilter(option.value);
    setOpen(false);
  }

  const defaultOption:IFilterOption = { label: 'All', value: null };
  const filterOptions = [ defaultOption, ...options ];

  const animateMenuClass = menuOpen ? 'translate-y-0' : '-translate-y-full';
  const menuContainerClass = menuOpen ? 'pointer-events-auto' : 'pointer-events-none';

  return <div className="absolute w-auto h-12 right-0 top-0 text-white font-medium pointer-events-auto">
    <MenuToggle
      Icon={menuOpen ? ActiveIcon : InactiveIcon}
      onClick={() => toggleMenu()}
      displayString={displayString}
    />
    <div className={`h-auto w-auto overflow-hidden ${menuContainerClass}`}>
      <div className={`transform transition-transform ${animateMenuClass}`}>
        {
          filterOptions.map((o, index) => <MenuSelect
            key={index}
            name={o.label ? o.label : (o.value || '')}
            selected={selected === o.value}
            onClick={() => selectOption(o)}
            Icon={o.Icon}
          />)
        }
      </div>
    </div>
  </div>
}

const FilterMenu:React.FC<IFilterMenuProps> = ({
  ...restProps
}) => {

  const [menuOpen, setMenuOpen] = useState(false);

  return <ManualFilterMenu
    menuOpen={menuOpen}
    setOpen={setMenuOpen}
    {...restProps}
  />
}

export default FilterMenu;