import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from '../views/Landing';
import Map from '../views/Map';
import Trials from '../views/Trials';
import Discover from '../views/Discover';
import Education from '../views/Education';
import Courthouse from '../views/Courthouse';
import About from '../views/About';

const DisplayRoute:React.FC = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Landing />
      </Route>
      <Route exact path='/map'>
        <Map />
      </Route>
      <Route exact path='/trials'>
        <Trials />
      </Route>
      <Route exact path='/discover'>
        <Discover />
      </Route>
      <Route exact path='/education'>
        <Education />
      </Route>
      <Route exact path='/courthouse'>
        <Courthouse />
      </Route>
      <Route exact path='/about'>
        <About />
      </Route>
    </Switch>
  )
};

export { DisplayRoute };