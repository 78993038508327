import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAtom } from "jotai";

import { Content, ImageHeader, Loader } from "../components";
import { API, HEADERS } from "../constants";
import { isSmallAtom } from "../atoms";
import parse from 'html-react-parser';

// import aboutHeaderImage from '../assets/images/about_header.jpg';

const About:React.FC = () => {
  
  const [isSmall] = useAtom(isSmallAtom);
  const [data, setData] = useState<any | null>(null);
  const [logos, setLogos] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.ABOUT, HEADERS);
      setData(response.data.about);
      setLogos(response.data.logos);
      // console.log(response.data);
    } catch(error) {
      console.error(error);
    }
  }

  const abstract = data?.abstract ? parse(data.abstract) : '';

  const textClass = isSmall ? 'w-full px-12 pb-10' : 'w-2/3 max-w-2xl pb-10';

  return <Content>
    {
      data === null ? <Loader isLoading={true} black/>
      :
      <div className='w-full h-full'>
        <ImageHeader
          bgImage={data.banner_image}
          title={data.title}
          text={data.description}
          subtext={data.banner_credit}
        />
        <div className='flex w-full place-content-center mt-10'>
          <div className={textClass}>
            {abstract}
          </div>
        </div>
        <div className={`flex w-full place-content-center`}>
        <div className={`flex justify-center flex-wrap gap-5 ${textClass}`}>
          {
            logos.map((logo, index) => {
              return logo.logo_url && <img
                key={index}
                className='w-32'
                src={logo.logo_url} alt='Logo'
              />
            })
          }
        </div>
        </div>
      </div>
    }
  </Content>
}

export default About;