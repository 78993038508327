import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Carousel from 'nuka-carousel';

import { Loader } from './Loader';
import { Modal } from './';
import { isSmallAtom } from "../atoms";
import { useAtom } from "jotai";
import { CircleButton } from "./Button";
import { ZoomItem } from "./ZoomItem";
import { VideoItem } from "./VideoItem";
import { ItemModalText, getModalTextWidth, SmallItemModalText } from "./ItemModalText";

import { ReactComponent as NextIcon } from '../assets/icons/button_next.svg';
import { ReactComponent as ZoomInIcon } from "../assets/icons/button_image_zoomIn.svg";
import { ReactComponent as CloseIcon } from '../assets/icons/button_close.svg'
import { ReactComponent as PlayButton } from '../assets/icons/button_play.svg'

interface IItemModalProps {
  data: any;
  setShowExternal?: Dispatch<SetStateAction<boolean>>;
  showModal?: boolean;
}

const MediaItem = ({
  src,
  addBottomGradient,
  isSmall,
  alt
}:{
  src: string,
  addBottomGradient: boolean,
  isSmall: boolean
  alt: string,
}) => {
  
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const imageHeight = isSmall ? 'h-smallImageHeight' : 'h-modal';
  const gradientStyle = {
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.7) 100%)'
  }

  return <>
    <div id='mediaItemContainer' className={`relative w-full h-full`}>
      { mediaLoaded || <Loader black isLoading/> }
      <img
        src={src}
        className={`w-full ${imageHeight} object-cover relative`}
        onLoad={() => setMediaLoaded(true)}
        alt={alt}
      />
      {
        addBottomGradient &&
        <div className={`w-full ${imageHeight} absolute top-0 pointer-events-none`} style={gradientStyle} />
      }
    </div>
  </>
}

const ArrowButton = ({
  isLeft,
  onClick,
  scrollShift,
  display,
}:{
  isLeft?:boolean;
  onClick: any;
  scrollShift?: boolean;
  display: boolean;
}) => {
  const directionClasses = isLeft ? 'transform rotate-180 ml-3' : scrollShift ? 'mr-6 ' : 'mr-3 ';
  const opacity = !display ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'
  
  return <CircleButton
    className={`transition-opacity bg-opacity-60 ${opacity} pr-3 ${directionClasses}` }
    onClick={onClick}
  >
    <NextIcon width='30px' height='30px' stroke="white" fill="none" strokeWidth={1.5}/>
  </CircleButton>
}

const MediaCarousel = ({
  media,
  setZoomItem,
  tailwindWidth,
  isSmall,
  curItem,
  setCurItem,
  hasScroll,
}:{
  media:any[],
  curItem: number,
  setCurItem: Dispatch<SetStateAction<number>>,
  setZoomItem: Dispatch<SetStateAction<number|null>>,
  tailwindWidth: string,
  isSmall: boolean,
  hasScroll: boolean
}) => {

  const single = media.length < 2;

  const config = {
    pagingDotsStyle: {
      fill: 'white',
      padding: '0.4rem',
      transform: 'scale(1.5)',
      zIndex: 10
    }
  }

  const containerStyle = isSmall ? {
    width: '100vw',
    height: '100vw',
  } : {
    width: '50%',
    height: '594px'
  }

  const containerClass = isSmall ? 'relative'
    : tailwindWidth + ' relative z-10';

  const isVideo = media[curItem].media_type === 'Audio/Video';
  
  return <>
    <div className={containerClass} style={containerStyle}>
      <Carousel
        width={'100%'}
        swiping={!single}
        dragging={!single}
        afterSlide={index => setCurItem(index)}
        slideIndex={curItem}
        renderCenterRightControls={single ? null : ({ nextSlide }) => (
          <ArrowButton
            onClick={nextSlide}
            scrollShift={hasScroll}
            isLeft={false}
            display={curItem < media.length-1}
          />
        )}
        renderCenterLeftControls={single ? null : ({ previousSlide }) => (
          <ArrowButton
            onClick={previousSlide} 
            isLeft
            display={curItem > 0}
          />
        )}
        withoutControls={single}
        defaultControlsConfig={config}
      >
        {
          media.map((item, index) => <MediaItem
            key={index}
            src={item.thumb_url}
            addBottomGradient={media.length > 1}
            isSmall={isSmall}
            alt={item.caption}
          />)
        }
      </Carousel>
      {
        !isVideo ?
        <CircleButton
          className={`absolute bg-opacity-60 ${hasScroll ? 'mr-6' : 'mr-3'} mb-3 right-0 bottom-0`}
          onClick={() => setZoomItem(curItem)}
        >
          <ZoomInIcon width='40px' height='40px' stroke="white" fill="none" strokeWidth={1.25}/>
        </CircleButton>
        :
        <div
          className='w-full h-full absolute top-0 flex place-content-center items-center pointer-events-none'
        >
          <div
            className='pointer-events-auto text-white hover:cursor-pointer hover:text-gray-300'
            onClick={() => setZoomItem(curItem)}
          >
            <PlayButton width='100px' height='100px' fill='currentColor'/>
          </div>
        </div>
      }
    </div>
  </>
}

const ItemModal:React.FC<IItemModalProps> = ({
  data,
  ...restProps
}) => {

  const [isSmall] = useAtom(isSmallAtom);
  const [zoomItem, setZoomItem] = useState<number|null>(null);
  const [curItem, setCurItem] = useState<number>(0);
  const [hasScroll, setHasScroll] = useState<boolean>(false);

  useEffect(() => {
    setCurItem(0);
    setZoomItem(null);
  }, [data])

  if (!data) return null;

  const media = data.media_items || [];
  
  const hasMedia = media.length > 0;
  const modalTextWidth = getModalTextWidth(isSmall, hasMedia);
  const modalWidth = isSmall ? 
    'w-screen' :
    hasMedia ? 'w-modal max-w-full' : 'w-modalText';

  const modalHeight = isSmall ? 'h-full' : 'h-modal';

  const containerClass = isSmall ? 'w-full h-full overflow-y-auto overflow-x-hidden' : 'flex inline-flex h-full';

  const shiftRightButtons = hasScroll && isSmall;

  const buttonText = data.button_text && data.button_text.length > 0 ? data.button_text : 'Book your visit';

  return <Modal {...restProps}>
      { zoomItem === null ?
      <Modal.Display tailwindWidth={modalWidth} tailwindHeight={modalHeight} useCloseBtn={false} >
        <Modal.Body>
          <>
            <div className={containerClass}>
              <Modal.Toggle>
                <CircleButton
                  className={`absolute opacity-80 z-20 right-0 top-0 mt-3 ${shiftRightButtons ? 'mr-6' : 'mr-3'}`}
                >
                  <CloseIcon width='34px' height='34px' stroke="white" strokeWidth={1.5} />
                </CircleButton>
              </Modal.Toggle>
              {
                isSmall && data.button_link && <a href={data.button_link} target='_blank' rel='noopener noreferrer'>
                  <button
                    className='z-20 w-full bottom-0 absolute px-textBtn py-4 font-medium text-white bg-btnBgColor hover:bg-btnBgHover flex justify-center'
                  >
                    {buttonText} <NextIcon width='25px' height='25px' stroke="white" fill="none" strokeWidth={2.5} />
                  </button>
                </a>
              }
              {
                hasMedia &&
                <MediaCarousel
                  media={data.media_items}
                  curItem={curItem}
                  setCurItem={setCurItem}
                  setZoomItem={setZoomItem}
                  tailwindWidth={modalTextWidth}
                  isSmall={isSmall}
                  hasScroll={shiftRightButtons}
                />
              }
              {
                isSmall ?
                <SmallItemModalText
                  data={data}
                  setHasScroll={setHasScroll}
                  hasMedia={hasMedia}
                />
                :
                <ItemModalText
                  data={data}
                  hasMedia={hasMedia}
                />
              }
            </div>
          </>
        </Modal.Body>
      </Modal.Display>
      :
      <Modal.Display tailwindWidth={'w-full'} tailwindHeight={'h-full'} useCloseBtn={false}>
        <Modal.Body>
          <>
            <div className='w-full h-full'>
              <div className="w-full h-full bg-bgSolid absolute inset-x-0 bottom-0">
                {
                  media[zoomItem].media_type === 'Audio/Video'
                  ?
                  <VideoItem
                    src={media[zoomItem].video_url}
                    caption={media[zoomItem].caption}
                    credit={media[zoomItem].credit}
                    subtitles={media[zoomItem].subtitle_url}
                  />
                  :
                  <ZoomItem
                    src={media[zoomItem].image_url}
                    caption={media[zoomItem].caption}
                    credit={media[zoomItem].credit}
                  />
                }
              </div>
              <CircleButton
                className='absolute z-10 right-0 mt-3 mr-3 opacity-80'
                onClick={() => setZoomItem(null)}
              >
                <CloseIcon width='34px' height='34px' stroke="white" strokeWidth={1.5} />
              </CircleButton>
            </div>
          </>
        </Modal.Body>
      </Modal.Display>
    }
  </Modal>
}

export default ItemModal;