import React, { useState, useEffect } from "react";
import * as constants from "../constants";
import parse from "html-react-parser";
import axios from "axios";
import { isSmallAtom } from "../atoms";
import { useAtom } from "jotai";
import _ from "lodash";

import { NavLink } from "react-router-dom";
import { Content, NavLinkButton, Loader } from "../components";
import { API, HEADERS } from "../constants";

// import bgVideo from '../assets/landing_1280x720_optimised.mp4';
import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";

const VideoBackground = ({ url }: { url: string }) => {
  const videoBgStyles = {
    ...constants.mainViewportHeightStyle,
    // zIndex: -1,
  };
  return (
    <div
      style={videoBgStyles}
      className="absolute bg-bgSolid w-screen animate-fadein"
    >
      <video
        autoPlay
        playsInline
        loop={true}
        muted
        id="video"
        className="h-full w-full object-cover"
      >
        <source src={url} type="video/mp4" className="animate-fadein" />
      </video>
    </div>
  );
};

const Landing: React.FC = () => {
  const [isSmall] = useAtom(isSmallAtom);
  const [data, setData] = useState<any | null>(null);
  const [reposition, setReposition] = useState(false);

  const checkRender = () => {
    setReposition(!reposition);
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", _.debounce(checkRender, 50));
    return () => {
      window.removeEventListener("resize", _.debounce(checkRender, 50));
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(API.LANDING, HEADERS);
      setData(response.data.introduction);
    } catch (error) {
      console.error(error);
    }
  };

  const description = data?.description ? parse(data.description) : "";
  const bgVideo = data?.video_url;

  const textWidthStyle = isSmall
    ? {
        paddingLeft: "12%",
        paddingRight: "12%",
        lineHeight: 1.12,
      }
    : {
        paddingLeft: "20%",
        paddingRight: "20%",
        lineHeight: 1.12,
      };

  return (
    <Content className="bg-timeline">
      {data === null ? (
        <Loader isLoading black />
      ) : (
        <>
          {bgVideo && <VideoBackground url={bgVideo} />}
          <div className="animate-fadeinDelay flex flex-wrap h-full w-full content-center relative z-10">
            <div className="w-full align-center text-center text-white">
              <h1 style={textWidthStyle}>{data.title}</h1>
              <div className="text-xl pt-5 pb-10" style={textWidthStyle}>
                {description}
              </div>
              {!isSmall && (
                <div className="flex justify-center">
                  <NavLinkButton to="./map">
                    Continue{" "}
                    <NextIcon
                      width="25px"
                      height="25px"
                      stroke="white"
                      fill="none"
                      strokeWidth={2}
                    />
                  </NavLinkButton>
                </div>
              )}
            </div>
            {isSmall && (
              <div className="w-full absolute bottom-0 h-navbarHt">
                <NavLink to={"./map"}>
                  <button className="w-full h-full font-medium bg-btnBgColor flex justify-center items-center text-white">
                    Continue{" "}
                    <NextIcon
                      width="25px"
                      height="25px"
                      stroke="white"
                      fill="none"
                      strokeWidth={2}
                    />
                  </button>
                </NavLink>
              </div>
            )}
          </div>
        </>
      )}
    </Content>
  );
};

export default Landing;
