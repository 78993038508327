import React from "react";
import { LinkButton } from ".";
import parse from 'html-react-parser';

import defaultThumb from '../assets/images/default_thumb.jpg';
import { ReactComponent as LocationIcon } from '../assets/icons/icon_location.svg';
import { ReactComponent as NextIcon } from '../assets/icons/button_next.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/button_download.svg';

import { useAtom } from "jotai";
import { isSmallAtom } from "../atoms";

const CatalogItem = (
  index: number,
  data: any,
  linkText: string,
  linkIcon: string,
  isSmall: boolean,
) => {
  
  const thumbUrl = data.thumbnail || defaultThumb;
  const caption = data.short_description;
  const title = data.title;
  const link_url = data.link_url || './';
  const location = data.location

  if (!thumbUrl || !caption || !title || !link_url) return null;

  const parsedCaption = parse(caption);
  const itemWidth = isSmall ? 'w-catalogSmall' : 'w-catalogThumb';

  return <div key={index} className={`space-y-3 animate-fadeinDelay ${itemWidth} mt-10`}>
    <a href={link_url} target='_blank' rel='noopener noreferrer'>
      <img
        className='w-full transition-opacity animate-fadeinDelay h-catalogThumb object-cover hover:opacity-80'
        src={thumbUrl}
        alt={caption}
      />
    </a>
    {
      location && <div className='font-medium text-btnTextActive text-lg flex flex-wrap content-center'>
        <LocationIcon
          stroke="#A1866E"
          strokeWidth={1}
          width="18px"
          height="25px"
          fill="none"
          className="mr-1"
        />
        {location}
      </div>
    }
    <div className='text-2xl font-bold'>
      {title}
    </div>
    <div className='h-max mb-8 leading-6 pb-5'>
      {parsedCaption}
    </div>
    <LinkButton to={link_url} target={'top'} rel='noopener noreferrer'>
      {linkText}{
        linkIcon === "next" ?
        <NextIcon width='25px' height='25px' stroke="white" fill="none" strokeWidth={2} />
        :
        <DownloadIcon width='25px' height='25px' stroke="white" fill="none" strokeWidth={2} />
      }
    </LinkButton>
  </div>
}

interface ICatalogListProps {
  data: any[],
  linkText:string,
  linkIcon: string,
  nospaces?: boolean,
  title?: string,
}

const CatalogList:React.FC<ICatalogListProps> = ({
  data,
  linkIcon,
  linkText,
  nospaces,
  title
}) => {

  const [isSmall] = useAtom(isSmallAtom);
  const itemWidth = isSmall ? 'w-catalogSmall' : 'w-catalogThumb';

  return <React.Fragment>
    {
      title &&
      <div className='flex justify-center w-auto'>
        <div className='grid w-auto gap-x-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'>
          <div className={`${itemWidth} mt-10 text-4xl font-bold`}>{title}</div>
          <div className={itemWidth}/>
          <div className={itemWidth}/>
        </div>
      </div>
    }
    <div className='flex justify-center w-auto'>
      <div className='grid w-auto gap-x-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'>
        { data.map((d, index) => CatalogItem(index, d, linkText, linkIcon, isSmall)) }
      </div>
    </div>
    {
      !nospaces && <div className='h-40'/>
    }
  </React.Fragment>
}

export const CatalogSections = ({
  sectionNames,
  sectionKey,
  data,
  ...restProps
}:ICatalogListProps & {
  sectionNames: string[],
  sectionKey: string,
}) => {

  const siteData = sectionNames.map((section) => {
    return data.reduce((arr, d) => {
      return (d[sectionKey] == section) ? [...arr, d] : arr;
    }, []);
  })

  return <>
    {
      siteData.map((section, index) => {
        if (section.length < 1) return null;
        return <div className='mb-12 w-auto' key={index}>
          
          <CatalogList
            data={section}
            // title={`${sectionNames[index]}${sectionKey === 'site' ? 's' : ''}`}
            title={`${sectionNames[index]}`}
            {...restProps}
            nospaces
          />
        </div>
      })
    }
    <div className='h-40'/>
  </>

}

export default CatalogList