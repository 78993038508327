import { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ShareModal } from "./ShareModal";
import { NavButton, ExternalNavButton } from "./NavButton";
import { useAtom } from "jotai";
import { showShareModalAtom } from "../atoms";

import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { ReactComponent as ShareIcon } from "../assets/icons/button_share.svg";

import { TICKETS_URL } from "../constants";

const Navbar = () => {

	const location = useLocation();
  const [showShareModal, setShowShareModal] = useAtom(showShareModalAtom);

	return <Fragment>
		<div className="flex bg-black h-navbarHt w-full z-50">
			<ShareModal showModal={showShareModal} setShowExternal={setShowShareModal}/>
			<div className='h-full flex flex-wrap content-center pl-3'>
				<NavLink to='./'>
					<div className='transition-colors flex flex-wrap fill-white leading-tight font-bold text-white items-center hover:text-btnTextHoverColor'>
						<Logo fill='currentColor' width='35px' height='35px' className='mr-3'/> 
						<span>Beechworth Courthouse<br/>Kelly Trials</span>
					</div>
				</NavLink>
			</div>
			<div className='h-full flex flex-wrap flex-grow justify-end content-center px-2 space-x-4'>
				<NavButton to='./map' location={location.pathname}>
					Map
				</NavButton>
				<NavButton to='./trials' location={location.pathname}>
					Trials
				</NavButton>
				<NavButton to='./courthouse' location={location.pathname}>
					Courthouse
				</NavButton>
				<NavButton to='./discover' location={location.pathname}>
					Discover
				</NavButton>
				<NavButton to='./education' location={location.pathname}>
					Education
				</NavButton>
				<NavButton to='./about' location={location.pathname}>
					About
				</NavButton>
				<ExternalNavButton to={TICKETS_URL} target="_blank">
					Tickets
				</ExternalNavButton>
				<button 
					className="pr-2"
					onClick={() => setShowShareModal(!showShareModal)}
				>
					<ShareIcon
						className="text-white fill-current hover:text-btnTextHoverColor"
						width="22px"
						height="22px"
					/>
				</button>
			</div>
		</div>
	</Fragment>
}

export default Navbar;