import { ReactComponent as ImagesIcon } from '../assets/icons/icon_images.svg';
import { ReactComponent as PeopleIcon } from '../assets/icons/icon_people.svg';
import { ReactComponent as ObjectsIcon } from '../assets/icons/icon_object.svg';
import { ReactComponent as TrialsIcon } from '../assets/icons/icon_trials.svg';

const TimelineIconType = (type: string, large: boolean = false) => {
  const props = {
    stroke: "#A1866E",
    strokeWidth: 1.5,
    width: (large ? "28px" : "25px"),
    height: (large ? "28px" : "25px"),
    fill: "none",
    className: "mr-2"
  }
  switch(type) {
    case 'People':
      return <PeopleIcon {...props} />
    case 'Images':
      return <ImagesIcon {...props} />;
    case 'Trials':
      return <TrialsIcon {...props} />;
    case 'Objects':
      return <ObjectsIcon {...props} />;
    default:
      return null;
  }
}

export const TimelineDateDisplay = (date: string, type?: string, large: boolean = false) => {
  const cn = 'font-medium text-btnTextActive flex flex-wrap content-center';
  return <div className={large ? cn + ' text-xl' : cn}>
    { type && TimelineIconType(type, large) }{ date }
  </div>
}

interface IDateDisplay {
  value: number;
  display: string;
}

const monthNames = [
  'January','February','March','April',
  'May','June','July','August',
  'September','October','November','December'
]

const convertDateFromCMS = (date: string | null):IDateDisplay | null => {
  if (!date) return null;
  const array = date.split('-').map(str => parseInt(str));
  const year = array[0];
  const month = array[1] || null;
  const day = array[2] || null;
  const yearStr = `${year}`;
  const monthStr = month !== null ? monthNames[month - 1] + ' ' : ''
  const dayStr = day !== null ? `${day} ` : '';
  return {
    value: year*10000 + (month || 0)*100*(100/12) + (day || 0)*(100/30),
    display: dayStr + monthStr + yearStr,
  }
}

export const prepDateForStories = async (data: any[]) => {
	const convertedDatesFromCMS = data.map((node: any, index: number) => {
		const date = convertDateFromCMS(node.date || null);
		return { ...node, date, }
	})

  const totalNodes = data.length;
  const addXposToData = convertedDatesFromCMS.map((node: any, index: number) => {
    const xpos = index*100.0/Math.max(1, totalNodes - 1)
    return { ...node, xpos }
  })

  /* --- Put this back for arranged dates --- */
	// const sortedDates = convertedDatesFromCMS.sort((a: any, b: any) => a.date.value > b.date.value ? 1 : -1);
	// const dateValueArray = convertedDatesFromCMS.map((d: any) => d.date.value);
	// const start = Math.min(...dateValueArray);
	// const diff = Math.max(...dateValueArray)-start;

	// const addXposToData = convertedDatesFromCMS.map((node: any) => {
	// 	const xpos = 100*(node.date.value - start)/(diff);
	// 	return { ...node, xpos }
	// });
  /* --- Put this back for arranged dates --- */
	
	return addXposToData;
}