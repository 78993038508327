import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";

import {
	Content,
	ImageHeader,
	CatalogSections,
	Loader,
} from "../components";

import {
	API,
	HEADERS
} from "../constants";

// import headerImage from '../assets/images/resources_header.jpg';

const Education:React.FC = () => {

	const [isLoading, setIsLoading] = useState(true);
	const [introData, setIntroData] = useState<any|null>(null);
	const [catalogData, setCatalogData] = useState<any[]|null>(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.RESOURCES, HEADERS);
			setIntroData(response.data.banner);
      setCatalogData(response.data.resource_nodes);
    } catch(error) {
      console.error(error);
    } finally {
			setIsLoading(false);
		}
  }

	return <Content>
		{ isLoading ?
				<Loader isLoading black/>
				: 
				<Fragment>
					<ImageHeader
						bgImage={introData.banner_image}
						title={introData.title}
						text={introData.description}
						subtext={introData.banner_credit}
					/>
					<CatalogSections
						sectionKey="resource_type"
						sectionNames={['School programs', 'Further resources']}
						data={catalogData || []}
						linkIcon={"Download"}
						linkText="Download"
					/>
				</Fragment>
			}
	</Content>
}

export default Education;