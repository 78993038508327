import { Dispatch, SetStateAction } from "react";
import { Modal } from ".";

export const IndigenousPopup = ({
  showModal,
  setShowExternal,
}: {
  showModal: boolean;
  setShowExternal: Dispatch<SetStateAction<boolean>>;
}) => (
  <Modal showModal={showModal} setShowExternal={setShowExternal}>
    <Modal.Display tailwindHeight="h-fit w-shareModal min-h-fit">
      <div className="w-full h-full text-center text-base md:text-lg px-14 py-14 md:py-16 md:px-20">
        <p>
          The Beechworth Courthouse Kelly Trials Project and Beechworth Historic
          Precinct acknowledges the traditional owners of the land on which the
          Courthouse sits, and pays respect to Elders past, present and
          emerging.
        </p>
        <br />
        <p>
          Visitors are advised that this site and the displays in the Courthouse
          may contain images, names or voices of people who have passed.
        </p>
      </div>
    </Modal.Display>
  </Modal>
);
