import { useState } from 'react';
import ReactPlayer from 'react-player';

import { CircleButton } from './Button';
import { CaptionBox } from './CaptionBox';

import { ReactComponent as CaptionIcon } from "../assets/icons/button_image_caption_updated.svg";

export const VideoItem = ({
  src,
  caption,
  credit,
  subtitles
}:{
  src: string,
  caption: string,
  credit: string,
  subtitles?: string,
}) => {

  const [showCaption, setShowCaption] = useState(false);
  const hasCaption = caption.length > 0 && credit.length > 0;
  const config = !subtitles || subtitles.length < 2 ? {
    file: { attributes: { controlsList: 'nodownload' } }
  } : {
    file: {
      attributes: {
        controlsList: 'nodownload',
        crossOrigin: 'true'
      },
      tracks: [
        {
          kind: 'subtitles',
          src: subtitles,
          srcLang: 'en',
          default: false,
          label: 'Captions'
        }
      ]
    }
  }

  return <>
    <div className='w-full h-full'>
      <ReactPlayer
        width='100%'
        height='100%'
        url={[src]}
        controls
        playing
        muted
        config={config}
      />
      <div id='interface' className="absolute inset-y-0 right-0 h-full pointer-events-none z-20">
        <div className="h-full flex flex-wrap place-content-center p-2">
          <div id='buttons' className='space-y-2 pointer-events-auto'>
            {
              hasCaption && <div className='relative height-0'>
                <CircleButton
                  className='bg-opacity-80'
                  onClick={() => setShowCaption(!showCaption)}
                >
                  <CaptionIcon width='50px' height='50px' fill="white" strokeWidth={0}/>
                </CircleButton>
                {
                  showCaption && <div className='absolute right-0 top-0'>
                    <CaptionBox
                      caption={caption}
                      credit={credit}
                      toggleShowCaption={setShowCaption}
                    />
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </>
}

