
import { NavLink } from "react-router-dom";

interface INavButtonText {
	children: string | React.ReactNode;
	to: string;
	location?: string;
	mobile?: boolean;
}

interface INavButtonProps extends INavButtonText {
  onClick?: any;
	target?: string;
}

const NavText = ({
	location,
	mobile,
	children,
	to,
}: INavButtonText) => {
	const textColor = '.' + location !== to ? 'text-white' : 'text-btnTextActive';
	const textSize = mobile ? ' text-3xl font-bold py-2' :  ' text-buttonSize font-medium';
	return <div className={`transition-colors ${textColor} ${textSize} hover:text-btnTextHoverColor`}>
		{children}
	</div>
}

export const NavButton = ({
	to,
  onClick,
	target,
	...restProps
}: INavButtonProps) => {
	return <NavLink
    to={to} 
    onClick={() => {
      if (onClick) onClick()
    }}
		target={target}
  >
		<NavText to={to} {...restProps}/>
	</NavLink>
}

export const ExternalNavButton = ({
	to,
  onClick,
	target,
	...restProps
}: INavButtonProps) => {
	return <a href={to} target={target}>
		<NavText to={to} {...restProps}/>
	</a>
}