import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";

import {
	Content,
	ImageHeader,
	Loader,
} from "../components";
import { CatalogSections } from "../components/CatalogList";

import {
	API,
	HEADERS
} from "../constants";

// import headerImage from '../assets/images/discover_header.jpg';

const Discover:React.FC = () => {

	const [isLoading, setIsLoading] = useState(true);
	const [introData, setIntroData] = useState<any|null>(null);
	const [catalogData, setCatalogData] = useState<any[]|null>(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.DISCOVER, HEADERS);
			setIntroData(response.data.banner);
      setCatalogData(response.data.discover_nodes);
    } catch(error) {
      console.error(error);
    } finally {
			setIsLoading(false);
		}
  }
	
	return <Content>
			{ isLoading ?
				<Loader isLoading black />
				: 
				<Fragment>
					<ImageHeader
						bgImage={introData.banner_image}
						title={introData.title}
						text={introData.description}
						subtext={introData.banner_credit}
					/>
					<CatalogSections
						sectionKey="site"
						sectionNames={['Historic places', 'Kelly destinations', 'Nature tourism']}
						data={catalogData || []}
						linkIcon={"next"}
						linkText="Find out more"
					/>
				</Fragment>
			}
	</Content>
}

export default Discover;