import React from "react";
import parse from 'html-react-parser';

interface IImageHeaderProps {
  readonly title: string;
  readonly text?: string;
  readonly subtext?: string | null;
  readonly bgImage:string;
}

const ImageHeader:React.FC<IImageHeaderProps> = ({
  bgImage,
  title,
  text,
  subtext,
}) => {

  const parsedText = parse(text || '');
  const parsedSubtext = parse(subtext || '');

  const bgImageStyle = { backgroundImage: `url('${bgImage}')` };

  return <div style={bgImageStyle} className={'w-full h-96 bg-cover'}>
    <div className='w-full bg-black bg-opacity-50 h-full flex flex-wrap place-content-center'>
      <div className='w-3/4 text-center text-white max-w-2xl leading-tight'>
        <h1>{title}</h1>
        <div className='text-xl mt-5'>
          {parsedText}
        </div>
        {
          parsedSubtext && <div className='mt-3 text-sm'>
            {parsedSubtext}
          </div>
        }
      </div>
    </div>
  </div>
}

export { ImageHeader }