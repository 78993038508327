import React, { useState, useEffect } from "react";
import axios from "axios";

import {
	Content,
	TimelineDisplay,
	Loader,
  prepDateForStories
} from "../components";
import { API, HEADERS } from "../constants";

import { ReactComponent as ImagesIcon } from '../assets/icons/icon_images.svg';
import { ReactComponent as PeopleIcon } from '../assets/icons/icon_people.svg';
import { ReactComponent as ObjectsIcon } from '../assets/icons/icon_object.svg';
import { ReactComponent as TrialsIcon } from '../assets/icons/icon_trials.svg';



const Trials:React.FC = () => {

	const [data, setData] = useState<any[]|null>(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get(API.STORIES, HEADERS);
			const modifiedData = await prepDateForStories(response.data.timeline_nodes);

      setData(modifiedData);
    } catch(error) {
      console.error(error);
    }
  }

  const options = [
    { value: 'People', Icon: PeopleIcon },
    { value: 'Images', Icon: ImagesIcon },
    { value: 'Objects', Icon: ObjectsIcon },
    { value: 'Trials', Icon: TrialsIcon },
  ]

	return <Content>
		<div className='w-full h-full bg-timeline'>
			<Loader isLoading={!data}>
				<TimelineDisplay
          data={data || []}
          useDatesToArrange={false}
          filterOptions={options}
          title='Trials'
        />
			</Loader>
		</div>
	</Content>
}

export default Trials;