
import parse from 'html-react-parser';
import { TimelineDateDisplay } from "./TimelineDateDisplay";
import { LinkButton } from './Button';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { ReactComponent as NextIcon } from '../assets/icons/button_next.svg';

export const getModalTextWidth = (isSmall: boolean, hasMedia: boolean) => isSmall || !hasMedia ? 'w-full' : 'w-1/2';

const BookVisitButton = ({
  title,
  link
}:{
  title?: string,
  link: string
}) => {
  
  const buttonText = title && title.length > 0 ? title : 'Book your visit';

  return <div className='grid w-full justify-center place-content-center h-1/6 text-buttonSize'>
    <LinkButton to={link} target='_blank' rel='noopener noreferrer'>
      {buttonText} <NextIcon width='25px' height='25px' stroke="white" fill="none" strokeWidth={2.5} />
    </LinkButton>
  </div>
}

export const SmallItemModalText = ({
  hasMedia,
  data,
  setHasScroll,
}:{
  hasMedia:boolean,
  data: any,
  setHasScroll: Dispatch<SetStateAction<boolean>>,
}) => {

  const desc = parse(data.description);
  const type = data.node_type;
  const date = data.date;
  const title = data.title;

  const modalTextWidth = getModalTextWidth(true, hasMedia);
  const topPadding = 'pt-8';
  const textPadding = 'px-8';

  useEffect(() => {
    const element = document.getElementById('scrollElement');
    setHasScroll(window.innerWidth > (element?.clientWidth || window.innerWidth));
  }, [])

  return <div className={`relative ${modalTextWidth} ${topPadding}`}>
    <div id='scrollElement' className='w-full pt-4 space-y-4 h-full overflow-y-auto -z-20 mb-12 pb-8'>
      { date &&
        <div className={textPadding}>
          {TimelineDateDisplay(date.display, type, true)}
        </div>
      }
      <div className={`font-bold text-4xl ${textPadding}`}>{title}</div>
      <div className={`pb-2 ${textPadding}`}>{desc}</div>
    </div>
  </div>
}

export const ItemModalText = ({
  hasMedia,
  data,
}:{
  hasMedia:boolean,
  data: any,
}) => {

  const desc = parse(data.description);
  const type = data.node_type;
  const date = data.date;
  const title = data.title;

  const modalTextWidth = getModalTextWidth(false, hasMedia);
  const topPadding = 'pt-14';
  const textPadding = 'px-12';

  const scrollHt = data.button_link ? 'h-5/6' : 'h-full'

  return <div className={`${modalTextWidth} h-full`}>
    <div className={`relative scrollText w-full ${scrollHt}`}>
      <div
        id='scrollGradientTop'
        className={`absolute w-full h-6 z-10 ${textPadding}`}
      >
        <div className='w-full h-full bg-gradient-to-b via-bgSolid from-bgSolid'/>
      </div> 
      <div
        id='scrollGradientBot'
        className={`absolute w-full h-6 bottom-0 z-10 ${textPadding}`}
      >
        <div className='w-full h-full bg-gradient-to-t via-bgSolid from-bgSolid'/>
      </div> 
      <div id='scrollElement' className={`relative w-full ${topPadding} space-y-4 h-full overflow-y-auto -z-20 pb-12`}>
        { date &&
          <div className={textPadding}>
            {TimelineDateDisplay(date.display, type, true)}
          </div>
        }
        <div className={`font-bold text-4xl ${textPadding}`}>{title}</div>
        <div className={`pb-2 ${textPadding}`}>{desc}</div>
      </div>
    </div>
    {
      data.button_link && <BookVisitButton
        link={data.button_link}
        title={data.button_text}
      />
    }
  </div>
}