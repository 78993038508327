import { useState, useEffect } from 'react';
import { Loader } from "./Loader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { CircleButton, MapZoomButton } from './Button';
import { CaptionBox } from './CaptionBox';

import { ReactComponent as ZoomInIcon } from "../assets/icons/button_map_zoomIn.svg";
import { ReactComponent as ZoomOutIcon } from "../assets/icons/button_map_zoomOut.svg";
import { ReactComponent as CaptionIcon } from "../assets/icons/button_image_caption_updated.svg";

import _ from 'lodash';

export const ZoomItem = ({
  src,
  caption,
  credit,
}:{
  src: string,
  caption: string,
  credit: string
}) => {

  const wrapperStyle = {
    height: '100%',
    width: '100%',
  }
  
  const [image, setImage] = useState<any>(null);
  const [showImage, setShowImage] = useState(false);
  const [showCaption, setShowCaption] = useState(false);
  const [initScale, setInitScale] = useState<number>(1);
  const [refresh, setRefresh] = useState<boolean>(false);
  const hasCaption = caption.length > 0 || credit.length > 0;

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setShowImage(true);
      setImage(image);

      const container = document.getElementById('container');
      const wd = container?.clientWidth || 360;
      const ht = container?.clientHeight || 360;
      const imgWd = image.width;
      const imgHt = image.height;
      const scaledWd = wd/imgWd;
      const scaledHt = ht/imgHt;
      if (scaledWd < scaledHt) {
        setInitScale(1);
        //console.log('using wRatio')
      } else {
        //console.log('usingHRatio', scaledHt, scaledWd, scaledHt/scaledWd);
        setInitScale(scaledHt/Math.min(1, scaledWd));
      }
      setRefresh(true);
    }
    image.src = src;
  }, [src])

  return <div className='w-full h-full' id='container'>
    <Loader black isLoading={!showImage}>
      <>
        <TransformWrapper
          centerOnInit
          minScale={initScale}
          initialScale={initScale}
          maxScale={4}
        >
          {({
            zoomIn,
            zoomOut,
            instance,
            resetTransform
          }) => {

            if (refresh) {
              setRefresh(false);
              resetTransform();
            }

            const isMinScale = instance.transformState.scale === initScale;
            const isMaxScale = instance.transformState.scale === 4;

            return <div className='w-full h-full relative'>
              {
                showImage && <TransformComponent wrapperStyle={wrapperStyle}>
                  <img
                    src={src}
                    id='image'
                    className='w-full h-full object-contain'
                    alt={caption}
                  />
                </TransformComponent>
              }
              <div id='interface' className="absolute inset-y-0 right-0 h-full pointer-events-none z-20">
                <div className="h-full flex flex-wrap place-content-center p-3">
                  <div id='buttons' className='space-y-2 pointer-events-auto'>
                    <MapZoomButton
                      onClick={() => zoomIn()}
                      className='bg-opacity-80'
                      disabled={isMaxScale}
                    >
                      <ZoomInIcon width='50px' height='50px' fill="none" stroke="white" strokeWidth={1.5}/>
                    </MapZoomButton>
                    <MapZoomButton
                      onClick={() => zoomOut()}
                      className='bg-opacity-80'
                      disabled={isMinScale}
                    >
                      <ZoomOutIcon width='50px' height='50px' fill="none" stroke="white"  strokeWidth={1.5}/>
                    </MapZoomButton>
                    {
                      hasCaption && <div className='relative height-0'>
                        <CircleButton
                          className='bg-opacity-80'
                          onClick={() => setShowCaption(!showCaption)}
                        >
                          <CaptionIcon width='50px' height='50px' fill="white" strokeWidth={0}/>
                        </CircleButton>
                        {
                          showCaption && <div className='absolute right-0 top-0'>
                            <CaptionBox
                              caption={caption}
                              credit={credit}
                              toggleShowCaption={setShowCaption}
                            />
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }}
        </TransformWrapper>
      </>
    </Loader>
  </div>
}