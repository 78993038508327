import { BrowserRouter as Router } from "react-router-dom";
import { DisplayRoute } from "./routes";
import { useEffect, useMemo, useState } from "react";
import { isSmallAtom, isLandscapeAtom } from "./atoms";
import { useAtom } from "jotai";
import { Navbar, MobileNavbar, IndigenousPopup } from "./components";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

import { mobileWidth } from "./constants";
import _ from "lodash";

const RenderApp = () => {
  const [isSmall, setIsSmall] = useAtom(isSmallAtom);
  const [, setIsLandscape] = useAtom(isLandscapeAtom);

  useEffect(() => {
    handleResize();
    window.addEventListener(
      "resize",
      _.debounce(() => handleResize(), 10)
    );

    return () => {
      window.removeEventListener(
        "resize",
        _.debounce(() => handleResize(), 10)
      );
    };
  }, []);

  const checkLandscape = () => {
    const landscape = window.innerWidth > window.innerHeight;
    setIsLandscape(landscape);
  };

  const handleResize = () => {
    isMobile && checkLandscape();
    setIsSmall(window.innerWidth < mobileWidth);
  };

  return (
    <>
      {isSmall ? <MobileNavbar /> : <Navbar />}
      <DisplayRoute />
    </>
  );
};

function App() {
  const [isLandscape] = useAtom(isLandscapeAtom);
  const [showIndigenousPopup, setShowIndigenousPopup] = useState<boolean>(true);

  const RenderMemo = useMemo(() => <RenderApp />, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showIndigenousPopup === true) setShowIndigenousPopup(false);
    }, 9000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Beechworth Courthouse Kelly Trials</title>
        {/* <meta name="robots" content="noindex" /> */}
        <meta
          name="description"
          content="Explore Kelly Gang history and discover how the trials of the Kelly family link to the Beechworth Historic Precinct."
        />
      </Helmet>
      <Router>{RenderMemo}</Router>
      {showIndigenousPopup && (
        <IndigenousPopup
          showModal={showIndigenousPopup}
          setShowExternal={setShowIndigenousPopup}
        />
      )}
      {isMobile && isLandscape && (
        <>
          <div className="absolute top-0 bg-black w-full h-full grid place-content-center z-50">
            <div className="text-white w-42 h-5">
              This site is best viewed in portrait mode.
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
